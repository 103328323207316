<template>
  <div class="scan">
    <div class="video-container" >
      <div @click="saoyisao" class="scan-box"></div>
      <video @click="saoyisao" class="video" id="video-1"></video>
      <div class="mask" @click="saoyisao">
        <div class="top">
          <div class="close" @click="close">
            <van-icon name="cross" color="#6A7374" />
          </div>
          <div class="more">...</div>
        </div>
        <div class="scan-success" v-if="scanSuccess">
          <div class="dot"></div>
          <div>扫描完成</div>
          <div>正在准备打开</div>
        </div>
        <div class="bottom" @click="saoyisao">
          <div class="tip" v-if="!scanSuccess">识别二维码 / 花草 / 动物 / 商品等</div>
          <div class="links" v-if="!scanSuccess">
            <div class="link">
              <div class="icon">
                <img src="/images/my-qr.png" alt="">
              </div>
              <div class="text">我的二维码</div>
            </div>
            <div class="link">
              <div class="icon">
                <img src="/images/dcim.png" alt="">
              </div>
              <div class="text">相册</div>
            </div>
          </div>
          <div class="btns">
            <div class="saoyisao" @click="saoyisao">扫一扫</div>
            <div class="fanyi">翻译</div>

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="camera-container" v-if="videoInputDevices.length">
      <label>摄像头：</label>
      <select v-model="currentVideoInputDevice">
        <option
          v-for="(videoInputDevice, index) in videoInputDevices"
          :key="index"
          :value="videoInputDevice"
        >
          {{ videoInputDevice.label=='Video device 2'?'后置摄像头':'前置摄像头' }}
        </option>
      </select>
    </div> -->
    <!-- <div class="scan-animation">
      <div class="scan-line"></div>
      <div class="scan-box"></div>
    </div> -->
  </div>
</template>
 
<script>
import {
  BrowserMultiFormatReader,
  ChecksumException,
  FormatException,
} from "@zxing/library";

export default {
  name: "Scan",
  data() {
    return {
      codeReader: new BrowserMultiFormatReader(),
      videoInputDevices: [],
      currentVideoInputDevice: {},
      decodeResult: undefined,
      scanSuccess: false
    };
  },
  methods: {
    async openScan() {
      const _this = this;
      _this.codeReader
        .getVideoInputDevices() //老版本listVideoInputDevices()
        .then((videoInputDevices) => {
          if (videoInputDevices && videoInputDevices.length) {
            if (videoInputDevices.length > 1) {
              videoInputDevices.reverse();
            } //防止先唤出前摄像头
            _this.videoInputDevices = videoInputDevices;
            _this.currentVideoInputDevice = videoInputDevices[0];
          }
        })
        .catch(() => {});
    },
 
    decodeFromInputVideo() {
      const _this = this;
      _this.codeReader.reset();
      // 多次
      _this.codeReader.decodeFromVideoDevice(
        _this.currentVideoInputDevice.deviceId,
        "video-1",
        (result, err) => {
          if (result) {
            _this.decodeResult = result;
          }
          if (err) {
            if (err instanceof ChecksumException) {
              alert(1);
              console.log(
                "A code was found, but it's read value was not valid."
              );
            }
            if (err instanceof FormatException) {
              alert(2);
              console.log("A code was found, but it was in a invalid format.");
            }
          }
        }
      );
    },
 
    successDecode() {
      // const _this = this;
      // alert(_this.decodeResult.text);
    },

    saoyisao() {
      this.scanSuccess = true
      setTimeout(() => {
        this.$router.push({ path: '/wehchat/fukuan' })
      }, 2000)
    },
    close() {
      this.$router.go(-1)
    }
  },
  watch: {
    currentVideoInputDevice: function () {
      this.decodeFromInputVideo();
    },
    decodeResult: function () {
      this.successDecode();
    },
  },
  mounted: function () {
    this.openScan();
    if (this.$route.query.isExpore) {
      this.isFromExpore = true
    }
  },
  unmounted: function () {
    this.codeReader.reset(); //关闭摄像头
  },
};
</script>
<style scoped lang="less" >
.scan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  color: #fff;
}
 

.video {
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  border-radius: 10px;
}
.video-container {
  position: relative;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .close {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #F0F1F5;
      }
      .more {
        color: #F0F1F5;
        font-size: 30px;
        line-height: 30px;
        margin-top: -15px;
      }
    }
    .scan-success {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      top: 50%;
      left: calc(~'50% - 50px');
      .dot {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #0F9F5A;
        border: 3px solid #b5b4b4;
      }
    }
    .bottom {
      .tip {
        text-align: center;
        margin-bottom: 20px;
      }
      .links {
        display: flex;
        justify-content: space-between;
        .link {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #8A837B;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .text {
            font-size: 14px;
          }
        }
      }
      .btns {
        display: flex;
        font-size: 18px;
        .saoyisao {
          color: #fff;
          position: relative;
          margin-left: calc(~"50% - 28px");
          &::after {
            content: '.';
            width: 10px;
            height: 10px;
            font-weight: bold;
            color: #fff;
            position: absolute;
            left: calc(~"50% - 2px");
            top: 14px;
          }
        }
        .fanyi {
          margin-left: 28px;
        }
      }
    }
  }
}
 
.camera-container {
  display: flex;
  align-items: center;
}
 
label {
  margin-right: 10px;
}
 
select {
  padding: 8px;
  border: 2px solid #ff9900;
  border-radius: 5px;
  background-color: transparent;
  color: #ff9900;
}
 
.scan-animation {
  position: relative;
  width: 200px;
  height: 200px;
  margin-top: 20px;
}
 
.scan-box {
  position: absolute;
  top: 0;
  left: 16%;
  width: 70%;
  height: 2px;
  box-sizing: border-box;
  background: linear-gradient(
    to left,
    #0074d9 0%,
    #0074d9 20%,
    transparent 40%,
    transparent 60%,
    #0074d9 80%,
    #0074d9 100%
  );
  animation: scanBoxAnimation 2s infinite;
}
 
@keyframes scanBoxAnimation {
  0% {
    transform: translateY(15vh);
  }
  50% {
    transform: translateY(70vh);
  }
  100% {
    transform: translateY(15vh);
  }
}
</style>